import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'IM_Hyemin-Regular';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2106@1.1/IM_Hyemin-Regular.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
      font-family: 'IM_Hyemin-Bold';
      src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2106@1.1/IM_Hyemin-Bold.woff2') format('woff');
      font-weight: normal;
      font-style: normal;
  }

  body {
    font-family: 'IM_Hyemin-Regular';
    word-break: keep-all;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
  body::-webkit-scrollbar {
    display: none;
  }
`;

export default GlobalStyles;
