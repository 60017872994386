import { createTheme } from '@mui/material';
import { mainTheme } from './mainTheme.js';

export const wantPx = (px) => {
  return (px / 16) * 14;
};

const muiTheme = createTheme({
  typography: {
    fontFamily: 'IM_Hyemin-Regular',
    button: {
      fontFamily: 'IM_Hyemin-Regular',
      fontSize: '1rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '1rem',
        },
      },
    },
  },
  palette: {
    primary: {
      main: mainTheme.colors.brownM,
      light: mainTheme.colors.brownL,
      dark: mainTheme.colors.brownD,
    },
    secondary: {
      main: mainTheme.colors.kakaocolor,
    },
    white: {
      main: mainTheme.colors.whiteM,
      dark: mainTheme.colors.whiteD,
    },
  },
});

export default muiTheme;

export const selectTheme = createTheme({
  typography: {
    fontFamily: 'IM_Hyemin-Regular',
    fontSize: wantPx(14),
    button: {
      fontSize: '1rem',
    },
  },
});
