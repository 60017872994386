const pixelToRem = (size) => `${size / 16}rem`;

export const colors = {
  brownM: '#D7CCC8',
  brownL: '#EFEBE9',
  brownD: '#BCAAA4',
  black900: '#212121',
  black500: '#9e9e9e',
  black300: '#E0E0E0',
  kakaocolor: '#f7e600',
  whiteM: '#ffffff',
  whiteD: '#999999',
};

export const fontSize = {
  M: pixelToRem(16),
  L: pixelToRem(24),
  XL: pixelToRem(32),
  XXL: pixelToRem(48),
  S: pixelToRem(14),
  XS: pixelToRem(12),
};

export const fontStyle = {
  title: `
  font-size: ${fontSize.L};
  line-height: 36px;
  font-family: 'IM_Hyemin-Bold';
  @media screen and (max-width: 480px) {
    font-size: ${fontSize.L};
    line-height: 28px;
  }
`,
  body: `
  font-size: ${fontSize.L};
  line-height: 28px;
  font-family: 'IM_Hyemin-Regular';
  @media screen and (max-width: 480px) {
    font-size: ${fontSize.M};
    line-height: 20px;
  }
`,
  desc: `
  font-size: ${fontSize.M};
  line-height: 20px;
  font-family: 'IM_Hyemin-Regular';
  @media screen and (max-width: 480px) {
    font-size: ${fontSize.S};
    line-height: 16px;
  }
`,
};

export const mainTheme = {
  colors,
  fontSize,
  fontStyle,
};
