export default function copy(content) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(content);
  } else {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = content;
    dummy.select();
    dummy.setSelectionRange(0, 9999);
    dummy.focus();
    document.execCommand('copy');
    dummy.setSelectionRange(0, 0);
    document.body.removeChild(dummy);
  }
}
