import './App.css';
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { RecoilRoot } from 'recoil';
import { auth } from './firebase';
import './css/Reset.css';
import GlobalStyles from './css/GlobalStyles';
import { ThemeProvider } from 'styled-components';
import { mainTheme } from './css/mainTheme';
import { ThemeProvider as MUIThemeProvider } from '@mui/material';
import muiTheme from './css/muiTheme';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Loading from './Pages/Loading';
import backgroundImg from './Components/common/emoji-background.png';
import Swal from 'sweetalert2';
import copy from './Components/common/copy';
import { signInAnonymously } from 'firebase/auth';

const Main = lazy(() => import('./Pages/Main'));
const PrivateMain = lazy(() => import('./Pages/PrivateMain'));
const MakeFlower = lazy(() => import('./Pages/MakeFlower'));
const MakeFlowerTo = lazy(() => import('./Pages/MakeFlowerTo'));
const Flower = lazy(() => import('./Pages/Flower'));
const NotFound = lazy(() => import('./Pages/NotFound'));
const BlogContent = lazy(() => import('./Components/blog/BlogContent'));
const BlogList = lazy(() => import('./Components/blog/BlogList'));
const ArMediapipe = lazy(() => import('./Components/Ar/ArMediapipe'));

function App() {
  const [init, setInit] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [uid, setUid] = useState('');
  const [email, setEmail] = useState('');
  const thisUser = auth.currentUser;

  useEffect(() => {
    const browserInfo = window.navigator.userAgent.toLowerCase();
    if (
      browserInfo.includes('kakao') ||
      browserInfo.includes('instagram') ||
      browserInfo.includes('naver') ||
      browserInfo.includes('whale') ||
      browserInfo.includes('everytimeapp') ||
      browserInfo.includes('bytelo')
    ) {
      Swal.fire({
        icon: 'error',
        title: '다른 브라우저에서 시도해주세요!',
        text: '지금 이 브라우저에서는 회원가입과 로그인이 안 될 수도 있어요! 다른 브라우저(Safari, Chrome, 삼성 인터넷 등)로 시도해주세요!',
        confirmButtonText: '링크 복사하기',
        confirmButtonColor: ({ theme }) => theme.colors.brownM,
        showCancelButton: true,
        cancelButtonText: '닫기',
      }).then((result) => {
        if (result.isConfirmed) {
          copy(window.location.href);
          Swal.fire({
            icon: 'success',
            title: '복사되었습니다!',
          });
        }
      });
    }
    auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
        setUid(user.uid);
        setEmail(user.email);
        sessionStorage.setItem('uid', user.uid);
      } else {
        setIsLoggedIn(false);
        setUid('');
        setEmail('');
      }
      setInit(true);
    });
  }, []);
  return (
    <div className="App">
      <GlobalStyles />
      <ThemeProvider theme={mainTheme}>
        <MUIThemeProvider theme={muiTheme}>
          <BG>
            <MainLayout>
              <RecoilRoot>
                <BrowserRouter>
                  <Suspense fallback={<Loading />}>
                    <Routes>
                      {init && (
                        <>
                          {thisUser ? (
                            <>
                              <Route path="/" element={<PrivateMain uid={uid} email={email} />} />
                              <Route path="makeflower" element={<MakeFlower />} />
                            </>
                          ) : (
                            <Route path="/" element={<Main />} />
                          )}
                          <Route path="flower/:flowerId" element={<Flower user={uid} />} />
                          <Route
                            path="makeflowerto/:uid"
                            element={<MakeFlowerTo isLoggedIn={isLoggedIn} curUid={uid} />}
                          />
                          <Route path="blog" element={<BlogList />} />
                          <Route path="blog/:id" element={<BlogContent />} />
                          <Route path="ar/:flowerId" element={<ArMediapipe />} />
                          <Route path="*" element={<NotFound />} />
                        </>
                      )}
                    </Routes>
                  </Suspense>
                </BrowserRouter>
              </RecoilRoot>
            </MainLayout>
          </BG>
        </MUIThemeProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

const BG = styled.div`
  background: url(${backgroundImg}) repeat;
  background-color: ${({ theme }) => theme.colors.brownL};
  @media screen and (max-width: 480px) {
    background: ${({ theme }) => theme.colors.brownL};
  }
  width: 100%;
  /* height: 100vh; */
  display: flex;
`;

const MainLayout = styled.div`
  min-height: 100vh;
  display: flex;
  max-width: 600px;
  /* max-height: 800px; */
  width: 96%;
  margin: auto;
  padding-top: 3vh;
  padding-bottom: 1vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;
