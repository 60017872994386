// Import the functions you need from the SDKs you need
import { initializeApp, getApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getFirestore,
  collection,
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore/lite';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getStorage, ref, getDownloadURL, uploadString } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider, getToken } from 'firebase/app-check';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
// export const app = initializeApp(firebaseConfig);
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider('6Lc4fgIeAAAAAETtEpPaIMKILckNsHpoYT_4udxt'),
//   isTokenAutoRefreshEnabled: true,
// });

// getToken(appCheck).then(() => console.log('success'));
function createFirebaesApp(config) {
  try {
    return getApp();
  } catch (e) {
    const app = initializeApp(config);
    const appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider('6Lc4fgIeAAAAAETtEpPaIMKILckNsHpoYT_4udxt'),
      isTokenAutoRefreshEnabled: true,
    });

    return app;
  }
}
const app = createFirebaesApp(firebaseConfig)

const analytics = getAnalytics(app);
export const db = getFirestore();
export const auth = getAuth();
export const storage = getStorage();
export const functions = getFunctions(app, 'asia-northeast3');

export const customAuth = (token) => signInWithCustomToken(auth, token);

export const actionCodeSetings = {
  url: 'https://chukahe.com/emailauth',
  handleCodeInApp: true,
};

export async function getImageURL(path, fileName) {
  const storageRef = ref(storage, `${path}/${fileName}`);
  const imageUrl = await getDownloadURL(storageRef);
  return imageUrl;
}

export async function getData(col, docName) {
  const docRef = doc(db, col, docName);
  const docSnap = await getDoc(docRef);
  return docSnap.data();
}

export async function updateData(col, docName, data) {
  const ref = doc(db, col, docName);
  await updateDoc(ref, data);
  // data는 어레이로 들어와야 함
}

export async function updateArray(col, docName, arrayName, arrayItem) {
  const ref = doc(db, col, docName);
  await updateDoc(ref, {
    [arrayName]: arrayUnion(arrayItem),
  });
}

export const addBouquet = httpsCallable(functions, 'addBouquet');

export async function uploadBouquetImg(fileName, fileData) {
  const storageRef = ref(storage, `Bouquets/${fileName}.png`);
  const uploadTask = await uploadString(storageRef, fileData, 'data_url');
}

export const confirmReceiver = httpsCallable(functions, 'confirmReceiver');

export const getBouquet = httpsCallable(functions, 'getBouquet');

export const kakaoAuth = httpsCallable(functions, 'KakaoAuth');

export const getNickname = httpsCallable(functions, 'getNickname');
