import React from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';

export default function InnerDiv({ children, verticalalign, gaptop, isColumn }) {
  return (
    <GridWrapper container>
      <Grid item xs={1} />
      <CustomGrid item xs={10} gaptop={gaptop}>
        <InnerGrid verticalalign={verticalalign} isColumn={isColumn}>
          {children}
        </InnerGrid>
      </CustomGrid>
      <Grid item xs={1} />
    </GridWrapper>
  );
}

const GridWrapper = styled(Grid)`
  min-height: 94vh;
  width: 100%;
  padding: 1rem 0;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13px);
  border-radius: 20px;
  /* overflow: hidden; */
`;

const CustomGrid = styled(Grid)`
  /* height: 100%; */

  display: flex;
  padding-top: ${({ gaptop }) => (gaptop ? '50px' : '')};
`;

const InnerGrid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ verticalalign }) => verticalalign};
  /* overflow-y: auto; */
`;
